const live = "https://uw730uccb8.execute-api.eu-west-1.amazonaws.com/dev/";

export const url = live;

export const getUser = {
  url: url + "user",
  method: "GET",
  header: {}
};

export const getDevice = {
  url: url + "deviceData",
  method: "GET",
  header: {}
};

export const getDeviceSetting = {
  url: url + "deviceSetting",
  method: "GET",
  header: {}
};

export const updateSensorSetting = {
  url: url + "UserSetting",
  method: "POST",
  header: {},
  data: {}
}

export const addUser = {
  url: url + "UserData",
  method: "POST",
  header: {},
  data: {}
}

export const updateUser = {
  url: url + "UserDataUpdate",
  method: "POST",
  header: {},
  data: {}
}

export const updateUseCaseUser = {
  url: url + "update-usecase",
  method: "PUT",
  header: {},
  data: {}
}

export const getTemplates = {
  url: url + "usercaseTemplate",
  method: "GET",
  header: {},
}

export const addSensorSetting = {
  url: url + "settingDetails",
  method: "POST",
  header: {},
  data: {}
}

export const UpdateAddSettingsByDeviceId = {
  url: url + "updatedeviceSettings",
  method: "POST",
  header: {},
  data: {}
}

export const updateDeviceSettingsUserID = {
  url: url + "updateSettingsUserId",
  method: "POST",
  header: {},
  data: {}
}

export const AllPowerUsers = {
  url: url + "powerusers",
  method: "GET",
  header: {},
}

export const createPowerUser = {
  url: url + "create-powerusers",
  // url1: url1 + "powerusers",
  method: "POST",
  header: {},
  data:{}
}

export const updateUserRole = {
  url: url + "update-userRole",
  method: "PUT",
  header: {},
  data:{}
}

export const poweruserFromUsers = {
  url: url + "get-poweruser",
  method: "GET",
  header: {},
}

export const updatePowerUser = {
  url: url + "updateUser",
  method: "PUT",
  header: {},
  data:{}
}

export const DeletePowerUser = {
  url: url + "deletePowerUser",
  method: "DELETE",
  header: {},
  data:{}
}

export const UsersFromPowerUser = {
  url: url + "UsersFromPowerUser",
  method: "GET",
  header: {},
}
