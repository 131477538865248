import React, { useState, useEffect, useContext } from 'react';
import './App.scss';
import {
  HashRouter,
  Redirect,
  BrowserRouter,
  Route,
  Switch,
  Router,
} from "react-router-dom";
import Login from "./component/signin/signIn";
import Registration from "./component/signup/signUp";
import Dashboard from "./component/dashboard/dashboard";
import { Auth } from "aws-amplify";
import Navbar from "./component/Appbar/newAppBar";
import { createBrowserHistory } from "history";
import { useSelector, useDispatch } from 'react-redux'
import { SetTheme, SetLanguage } from './redux/action';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Setting from './component/settings/setting';
import SignUpSuccess from './component/signup/SignupSuccess';
// import AdminSignUp from './component/AdminSignUp/adminSignUp';
import UserList from './component/userList/userList';
import SensorList from './component/sensorList/sensorList';
import AddSensor from './component/addSensor/addSensor';
import Devices from './component/device/devices'
import ForgotPassword from './component/ForgotPassword/forgotPassword';
import ResetPassword from './component/ResetPassword/resetPassword';
import UpdatePassword from './component/ChangePassword/changePassword';
import Poweruser from './component/poweruser/Poweruser';
import Role from './component/Role/Role';
import { setTranslations, setDefaultLanguage, withTranslation } from 'react-multi-lang'
import de from './translations/de.json'
import en from './translations/en.json'
import fr from './translations/fr.json'
import it from './translations/it.json'
import ReactNotification, { store } from 'react-notifications-component';
import UseCaseList from './component/UseCase/usecaseList'
import AddUseCase from './component/UseCase/addUseCase'
import AddPoweruser from './component/poweruser/addPoweruser'

setTranslations({ de, en, fr, it })
export const history = createBrowserHistory();
function App(props: any) {
  const [isAuthenticated, setAuthStatus] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);


  const theme = useSelector((state: any) => (state.themeData))
  const SLanguage = useSelector((state: any) => state.languageData)
  const user = useSelector((state: any) => state.LoginData)
  setDefaultLanguage(SLanguage)

  const dispatch = useDispatch()
  const toggleTheme = () => {
    dispatch(SetTheme(theme === "light" ? "dark" : "light"))
  }

  const changeLanguage = (e: any) => {
    dispatch(SetLanguage(e.target.value))
    setDefaultLanguage(e.target.value)
  }

  const color = theme === "light" ? "#000" : "#FFF";
  const backgroundColor = theme === "light" ? "#FFF" : "#000";

  document.body.style.color = color;
  document.body.style.backgroundColor = backgroundColor;

  const darkTheme = createTheme({
    palette: {
      type: theme
    }
  });

  let location = window.location;
  let isXingUrl = location.pathname && location.pathname.match(/^\/xing_jobs/);

  return (
    <div className="App">
      <ReactNotification />
      <header className="App-header">
        {!isAuthenticating && (
          <div>
            <BrowserRouter>
              {!isXingUrl && (
                <Navbar auth={user} onClick={toggleTheme} theme={theme} setLanguage={changeLanguage} SLanguage={SLanguage} />
              )}
              <ThemeProvider theme={darkTheme}>
                <Switch>
                  <Route
                    exact
                    path="/dashboard"
                    render={(props: any) => <Dashboard {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/setting"
                    render={(props: any) => <Setting {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/users"
                    render={(props: any) => <UserList {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/sensors"
                    render={(props: any) => <SensorList {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/addSensors"
                    render={(props: any) => <AddSensor {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/devices"
                    render={(props: any) => <Devices {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/setting"
                    render={(props: any) => <Setting {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/"
                    render={(props: any) => <Login {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/signup"
                    render={(props: any) => <Registration {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/signup_success"
                    render={(props: any) => <SignUpSuccess {...props} auth={user} />}
                  />
                  {/* <Route
                    exact
                    path="/adminSignUp"
                    render={(props: any) => <AdminSignUp {...props} auth={user} />}
                  /> */}
                  <Route
                    exact
                    path="/forgotPassword"
                    render={(props: any) => <ForgotPassword {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/resetPassword"
                    render={(props: any) => <ResetPassword {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/changePassword"
                    render={(props: any) => <UpdatePassword {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/Poweruser"
                    render={(props: any) => <Poweruser {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/Role"
                    render={(props: any) => <Role {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/UseCase"
                    render={(props: any) => <UseCaseList {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/addUseCase"
                    render={(props: any) => <AddUseCase {...props} auth={user} />}
                  />
                  <Route
                    exact
                    path="/addPoweruser"
                    render={(props: any) => <AddPoweruser {...props} auth={user} />}
                  />

                </Switch>
              </ThemeProvider>
            </BrowserRouter>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;