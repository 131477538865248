import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import FormErrors from "../validation/FormErrors";
import Validate from "../validation/FormValidation";
import { Auth } from "aws-amplify";
import "./signIn.scss";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { LoginDetail, SetLanguage, Vanish } from "../../redux/action";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

export default function Login(props: {
	auth: {
		setAuthStatus: (arg0: boolean) => void;
		setUser: (arg0: any) => void;
	};
	history: string[];
	location: any;
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = React.useState(0);
	const [verifyDisabled, setVerifyDisabled] = React.useState(true);
	const [verificationCode, setVerificationCode] = React.useState("");
	const [state, setState] = useState({
		username: "",
		password: "",
		surname: "",
		errors: {
			cognito: {
				code: "",
				message: "",
				name: "",
			},
			blankfield: false,
		},
	});

	useEffect(() => {
		Auth.signOut();
		dispatch(Vanish());
		if (props.location.search) {
			const str = props.location.search.replaceAll("?", "");
			dispatch(SetLanguage(str));
		}
	}, []);

	const clearErrorState = () => {
		setState({
			...state,
			errors: {
				cognito: {
					code: "",
					message: "",
					name: "",
				},
				blankfield: false,
			},
		});
	};

	const onInputChange = async (event: {
		target: { name: any; value: any };
	}) => {
		const { name, value } = event.target;
		await setState({ ...state, [name]: value });

		//document.getElementById(event.target.id).classList.remove("is-danger");
	};
	const onVerificationInputChange = async (event: {
		target: { value: any };
	}) => {
		const { value } = event.target;
		await setVerificationCode(value);
		setVerifyDisabled(value.length == 6 ? false : true);
	};

	const redirectRegister = () => {
		history.push("/signup");
	};
	const redirectMoileupdate = () => {
		history.push("/mobileNoUpdate");
	};
	const redirectForgotPassword = () => {
		history.push("/forgotPassword");
	};
	const sendCodeToVerifyAccount = async () => {
		await Auth.resendSignUp(state.username);
	};
	const verifyAccount = async () => {
		try {
			clearErrorState();
			let verificationStatus = await Auth.confirmSignUp(
				state.username,
				verificationCode
			);
			if (verificationStatus == "SUCCESS") {
				setState({
					username: "",
					password: "",
					surname: "",
					errors: {
						cognito: {
							code: "CodeMismatchException",
							message: "CodeMismatchException",
							name: t("Please enter correct Code"),
						},
						blankfield: false,
					},
				});
				setActiveStep(0);
			}
		} catch (error) {
			let err = null;
			!error.message ? (err = { message: error }) : (err = error);

			setState({
				...state,
				errors: { cognito: err, blankfield: false },
			});

			console.log("error confirming sign up", error);
		}
	};

	const handleKeyPress = async (event :any) => {
		if (event.key === 'Enter') { 
			if (event.target.name === "username"
			|| event.target.name === "password") {
				handleSubmit(event);
			}
			if (event.target.name === "code") {
				verifyDisabled || verifyAccount();
			}
	}
	}

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();

		// Form validation
		clearErrorState();
		const error = Validate(event, state);
		if (error) {
			setState({ ...state, ...error });
		}

		if (state.username === "" && state.password === "") {
			let err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t("Please enter a username & password"),
			};
			setState({ ...state, errors: { cognito: err, blankfield: false } });
		} else if (state.username === "" && state.password) {
			let err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t("Please enter a username"),
			};
			setState({ ...state, errors: { cognito: err, blankfield: false } });
		} else if (state.password === "" && state.username) {
			let err = {
				code: "InvalidParameterException",
				name: "InvalidParameterException",
				message: t("Please enter a password"),
			};
			setState({ ...state, errors: { cognito: err, blankfield: false } });
		} else {
			// AWS Cognito integration here
			try {
				const user = await Auth.signIn(state.username, state.password);
				dispatch(LoginDetail(user));
				dispatch(
					SetLanguage(user.attributes["custom:custom:language"])
				);
				if (user.attributes["custom:role"] === "admin" || user.attributes["custom:role"] === "poweruser") {
					history.push("/users");
				} else {
					history.push("/dashboard");
				}
			} catch (error) {
				let err = null;
				!error.message ? (err = { message: error }) : (err = error);
				if (err.message === "User is not confirmed.") {
					// sendCodeToVerifyAccount(); // Resend is not needed
					setActiveStep(1);
					clearErrorState();
				}
				if (err.message === "Incorrect username or password." 
					|| err.message === "User does not exist."
					|| err.message === "Network error") {
						setState({ 
							...state,
							errors: { cognito: err, blankfield: false },
						});
				}
			}
		}
	};
	const t = useTranslation();

	return (
		<section className="section">
			<div className="container signInContainer">
				<div className="avtarDiv">
					<Avatar></Avatar>
				</div>
				<div className="lodinHeader">{t("Sign In")}</div>
				<form
					onSubmit={handleSubmit}
					className="loginForm"
					noValidate
					autoComplete="off"
				>
					{activeStep === 0 && (
						<div className="signInFormMargin">
							<div className="errorMsg">
								<FormErrors formerrors={state.errors} />
							</div>
							<div>
								<CssTextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="username"
									name="username"
									label={t("Username")}
									defaultValue={state.username}
									onChange={onInputChange}
									onKeyPress={handleKeyPress}
									// autoComplete="username"
								/>
							</div>
							<div>
								<CssTextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="password"
									name="password"
									label={t("Password")}
									type="password"
									defaultValue={state.password}
									onChange={onInputChange}
									onKeyPress={handleKeyPress}
									// autoComplete="current-password"
								/>
							</div>
							{console.log(
								"state.errors.cognito => ",
								state.errors.cognito
							)}
							{_.values(state.errors.cognito).length &&
							(state.errors.cognito.message 
								===	"Incorrect username or password." 
								||state.errors.cognito.message 
								===	"User does not exist." )? (
								<div style={{ textAlign: "center" }}>
									<Link
										href="javascript:void(0)"
										variant="body2"
										onClick={() => {
											redirectForgotPassword();
										}}
									>
										{t("Forgot password")}?
									</Link>
								</div>
							) : (
								""
							)}

							<div className="registerBtn">
								<Button
									type="button"
									fullWidth
									variant="outlined"
									onClick={handleSubmit}
								>
									{t("Sign In")}
								</Button>
							</div>
							<div className="loginRouteLink">
								<div>
									<Link
										// href="javascript:void(0)"
										onClick={() => {
											redirectRegister();
										}}
										variant="body2"
									>
										{t("Don't have an account? Sign Up")}
									</Link>
								</div>
							</div>
						</div>
					)}
					{activeStep === 1 && (
						<div>
							<div className="errorMsg">
								<FormErrors formerrors={state.errors} />
							</div>
							<div>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<CssTextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="code"
										name="code"
										label={t("Verification Code")}
										type="code"
										defaultValue={verificationCode}
										onChange={onVerificationInputChange}
										onKeyPress={handleKeyPress}
										autoComplete="code"
										autoFocus
									/>
								</Grid>

								<Button
									type="button"
									fullWidth
									variant="outlined"
									onClick={verifyAccount}
									disabled={verifyDisabled}
								>
									{t("Verify Account")}
								</Button>
							</div>
						</div>
					)}
				</form>
			</div>
		</section>
	);
}
