import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Grid,
	MenuItem,
	CircularProgress,
	TextField,
	Button,
	Paper,
	Dialog,
	DialogActions,
	DialogTitle,
	useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-multi-lang";
import {
	getTemplates,
	getUser,
	getDeviceSetting,
	updateUseCaseUser
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { SetTemplateDetail, SetUseCaseList } from "../../redux/action";
import _ from "lodash";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CropFreeIcon from "@material-ui/icons/CropFree";
import QrReader from "react-qr-reader";
import { store } from "react-notifications-component";
// import "./addSensor.scss";
import InputAdornment from "@material-ui/core/InputAdornment";

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

const AddUseCase: React.FC = (props: any) => {
	const t = useTranslation();
	const history = useHistory();
	const userId = useSelector((state: any) => state.userId);
	let allUsers = useSelector((state: any) => state.allUsers);
	const dispatch = useDispatch();
	const [templateList, setTemplates] = React.useState([]);
	const [sTypeId, SetSTypeId] = React.useState("");
	const [template, SetTemplate] = React.useState([]);
	const [userTemplates, SetUserTemplate] = React.useState([]);
	const [deviceTemplate, SetDeviceTemplate] = React.useState<any>([]);
	const [apiCall, setApiCall] = React.useState(false);
	const [result, setResult] = React.useState("No result");
	const [scanIndex, setScanIndex] = React.useState(null);
	const [tempApiCall, setTempApiCall] = React.useState(false);
	const [allSettingList, setAllSettingList] = React.useState([]);
	const user = useSelector((state: any) => state.LoginData);
	const sUserDetail = useSelector((state: any) => state.sensorList);
	const [selectedUserDetail, setSelectedUserDetail] =
		React.useState(sUserDetail);
	const [existDeviceId, setExistDeviceId] = React.useState(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [openDialog, setOpenDialog] = React.useState(false);


	const getAllTemplates = async () => {
		let request = _.clone(getTemplates);
		request.url = request.url;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);

		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetTemplateDetail([]));
			setTemplates([]);
			SetSTypeId("");
			SetTemplate([]);
			SetUserTemplate([]);
			SetDeviceTemplate([]);
			if (result.data.data.allUseCaseTemplates) {
				if (result.data.data.allUseCaseTemplates.length) {
					dispatch(
						SetTemplateDetail(result.data.data.allUseCaseTemplates)
					);
					setTemplates(result.data.data.allUseCaseTemplates);
				}
			}
			setTempApiCall(true);
		}
	};

	useEffect(() => {
		getAllTemplates();
	}, []);

	const handleChange = async (e: any) => {
		SetSTypeId(e.target.value);
		SetTemplate([]);
		const promises = templateList.map((detail: any, index: any) => {
			if (detail.UseCaseId === e.target.value) {
				SetTemplate(detail.template);
				SetUserTemplate(detail.template.userTemplate);
				SetDeviceTemplate(detail.template.DeviceSettingsTemplate);
			}
		});
	};

	const handleError = (err: any) => {
		console.error(err);
	};
	const handleScan = (data: any) => {
		let value = data;
		setResult(value);
	};

	const backToAdd = () => {
		setScanIndex(null);
	};

	const addDeviceDetail = async () => {
		setApiCall(true);
		const useCaseArr : any = []
		const usecase : any = {}
		usecase[sTypeId] = true
		useCaseArr.push(usecase);
		
		if(selectedUserDetail.Poweruser.PoweruserUsecases.length > 0){
			for (let i = 0; i < selectedUserDetail.Poweruser.PoweruserUsecases.length; i++) {
				const element = selectedUserDetail.Poweruser.PoweruserUsecases[i];
				useCaseArr.push(element)
			}
		}
		let request;
			request = _.clone(updateUseCaseUser);
			request.url = request.url
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
			console.log("request header",request.header);
			const data : any = {
				"UserID" : selectedUserDetail.UserID,
				"useCase" : useCaseArr
			}
			request.data = data;
		
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else{
			history.push('/UseCase')
			setApiCall(false);
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const overwriteSensorDetail = () => {
		let templateDetail: any = template;
		let index = _.findIndex(
			templateDetail.DeviceSettingsTemplate,
			function (o: any) {
				return o.DeviceID === existDeviceId;
			}
		);
		templateDetail.DeviceSettingsTemplate[index].update = true;
		setExistDeviceId(null);
		setOpenDialog(false);
		addDeviceDetail();
	};
	return (
		<div className="addSensorDiv">
			<Paper className="paperM">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span>
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Name
								: ""}
							{", "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Street
								: ""}{" "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Place
								: ""}{" "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Postcode
								: ""}
						</span>
					</Grid>
				</Grid>
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={3} md={3} lg={3}>
					{scanIndex === null ? (
						tempApiCall ? (
							<CssTextField
								select
								className="sensorDropdown"
								id="outlined-select-currency"
								label="Type"
								variant="outlined"
								value={sTypeId}
								onChange={(e) => handleChange(e)}
							>
								{templateList.length &&
									templateList.map(
										(data: any, index: any) => (
											<MenuItem
												key={data.UseCaseId}
												value={data.UseCaseId}
											>
												{t(data.UseCaseId)}
											</MenuItem>
										)
									)}
								)
							</CssTextField>
						) : (
							<Paper className="addSensorPaper">
								<CircularProgress />
							</Paper>
						)
					) : (
						<Button variant="outlined" onClick={backToAdd}>
							{t("Back")}
						</Button>
					)}
				</Grid>
			</Grid>

			{scanIndex !== null ? (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<QrReader
							delay={300}
							facingMode={"environment"}
							showViewFinder={true}
							onError={handleError}
							onScan={handleScan}
						/>
					</Grid>
				</Grid>
			) : userTemplates.length ? (
				<>
					<br />
					<Grid item xs={12}>
						<Button
							variant="outlined"
							disabled={apiCall ? true : false}
							onClick={addDeviceDetail}
						>
							{apiCall ? <CircularProgress /> : t("Update")}
						</Button>
					</Grid>
					
					<Dialog
						fullScreen={fullScreen}
						open={openDialog}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
						className="dialog"
					>
						<DialogTitle id="responsive-dialog-title">
							{"Wan't to overwrite existing sensor settings? (DeviceID = " +
								existDeviceId +
								")"}
						</DialogTitle>
						<DialogActions>
							<span className="deleteBtn">
								<Button
									variant="outlined"
									onClick={overwriteSensorDetail}
									color="primary"
								>
									Overwrite
								</Button>
							</span>
							<span className="cancelBtn">
								<Button
									autoFocus
									variant="outlined"
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</Button>
							</span>
						</DialogActions>
					</Dialog>
				</>
			) : (
				""
			)}
		</div>
	);
};
export default AddUseCase;
