import React, { useState, useEffect } from "react";
import "./chartDetail.scss";
import { Typography, InputLabel, Grid, TextField, Checkbox, Button, Tooltip, Select, MenuItem, FormControl, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-multi-lang'
import _ from "lodash";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DropdownDetails } from './data'
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
interface Types {
    auth: any;
    detail: any
}

const HtmlTooltip = withStyles ({
    arrow: {
      color: "#c7d100",
    },
    tooltip: {
      backgroundColor: "#c7d100",
      color: "black"
    },
  })(Tooltip);

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#c7d100',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#c7d100',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                // borderColor: '#c7d100',
            },
            '&:hover fieldset': {
                borderColor: '#c7d100',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c7d100',
            },
            '&.Mui-disabled fieldset': {
                borderColor: '#c7d100',
            },
        },
    },
})(TextField);

let initialStaticHoverArray : any[];
initialStaticHoverArray = [
    false,false,false,false,false,false,false,false,false,false,
    false,false,false,false,false,false,false,false,false,false,];

export default function SensorSetting(props: any) {
    const user = useSelector((state: any) => state.LoginData);
    let {selectedPowerUserDetail} = props
    const t = useTranslation()
    const [sensorDetail, setSensorDetail] = useState(props.detail)
    useEffect(() => {
        setSensorDetail(props.detail)
    }, [props.detail])

    const handleChanage = (e: any, index: any) => {
        let newEdit = { ...sensorDetail };
        newEdit[0].UserSettings[index].settingValue = e.target.value;
        newEdit = _.map(newEdit)
        setSensorDetail(newEdit);
    }

    const contactSetting = (e: any, index: any, sIndex: any) => {
        let newEdit = { ...sensorDetail };
        let sValue = JSON.parse(newEdit[0].UserSettings[index].settingValue)
        sValue[sIndex] = sValue[sIndex] ? false : true
        newEdit[0].UserSettings[index].settingValue = JSON.stringify(sValue)
        newEdit = _.map(newEdit)
        setSensorDetail(newEdit);
    }

    const handleCheckedValue = (e: any, index: any) => {
        let newEdit = { ...sensorDetail };
        let sValue = JSON.parse(newEdit[0].UserSettings[index].settingValue);
        sValue = sValue ? false : true
        newEdit[0].UserSettings[index].settingValue = JSON.stringify(sValue)
        newEdit = _.map(newEdit)
        setSensorDetail(newEdit);
    }

    const updateSetting = () => {
        props.updateSettings(sensorDetail[0])
    }

    const [StaticHoverArray, setStaticHoverArray] = React.useState(initialStaticHoverArray)

    const handHoverChange = async (index: any, state: boolean) => {
        let HoverArray : any[] =  [...StaticHoverArray]
        HoverArray.map((detail: any, indexHover: any) => {
            if (indexHover === index){
                HoverArray[indexHover] = state
            }else{
                HoverArray[indexHover] = false
            }
            })
            if (HoverArray[index] !== StaticHoverArray[index]){
                setStaticHoverArray(HoverArray)
            }
    }

    
    let nameInDropdownDetails = false;
    return (
        <div className="sensorSetting">
            {((user.attributes['custom:role'] === 'admin' || user.attributes['custom:role'] === 'user') && sensorDetail.length) || ((user.attributes['custom:role'] === 'poweruser' && selectedPowerUserDetail?.Poweruser?.PoweruserGlobalPermission['SettingsRead'] === true) && sensorDetail.length) ?
                sensorDetail[0].UserSettings.length ?
                    <>
                        <Grid direction="row" container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('Setting for this sensor type')}</Typography>
                            </Grid>
                        </Grid>
                        {sensorDetail[0].UserSettings.map((detail: any, index: any) =>
                            <Grid direction="row" container spacing={3}>
                                <Grid item xs={5} sm={3} md={3} lg={2}>
                                    <Typography className="settingName">{t(detail.settingName)}:</Typography>
                                </Grid>
                                <Grid item container xs={7} sm={9} md={9} lg={10}>
                                    {
                                        detail.settingName === 'WhatsApp' || detail.settingName === 'SMS' || detail.settingName === 'Phone' ?
                                            detail.settingValue.length ?
                                                <>
                                                    <Grid item xs={11} sm={4} md={2} lg={2}>
                                                        {JSON.parse(detail.settingValue).map((sDetail: any, sIndex: any) =>
                                                            <Checkbox
                                                                className="settingCheckbox"
                                                                name={detail.settingName}
                                                                defaultChecked={sDetail ? sDetail : false}
                                                                onChange={(e) => contactSetting(e, index, sIndex)}
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                                            <ClickAwayListener onClickAway={(e) => handHoverChange(index,false)}>
                                                                <div>
                                                                    <HtmlTooltip
                                                                        PopperProps={{
                                                                            disablePortal: true,
                                                                        }}
                                                                        onClose={(e) => handHoverChange(index,false)}
                                                                        open={StaticHoverArray[index]}
                                                                        disableFocusListener
                                                                        disableHoverListener
                                                                        disableTouchListener
                                                                        title={t(detail.settingName+'Info')}
                                                                        arrow
                                                                        placement="right"
                                                                    >
                                                                        <InfoOutlinedIcon onClick={(e) => handHoverChange(index,true)} 
                                                                        onMouseOver={(e) => handHoverChange(index,true)} 
                                                                        />

                                                                    </HtmlTooltip>
                                                                </div>
                                                            </ClickAwayListener>
                                                    </Grid>
                                                </>
                                                : ''
                                            :
                                            detail.settingValue === 'true' || detail.settingValue === 'false' ?
                                                <>
                                                    <Checkbox
                                                        name={detail.settingName}
                                                        defaultChecked={detail.settingValue === 'true' ? true : false}
                                                        onChange={(e) => handleCheckedValue(e, index)}
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                </>
                                                :
                                                <>
                                                {nameInDropdownDetails = false}         
                                                {DropdownDetails.map(( DropDownData :any , indexDropDown: any) =>
                                                    detail.settingName === DropDownData.DropdownName ?
                                                        <> 
                                                        {nameInDropdownDetails = true}
                                                            <Grid item xs={11} sm={4} md={2} lg={2}>
                                                                <CssTextField
                                                                    select
                                                                    id="outlined-select-currency"
                                                                    variant="outlined"
                                                                    value={detail.settingValue}
                                                                    onChange={(e) => handleChanage(e, index)}
                                                                    className="settingDropdown"
                                                                >
                                                                    {DropDownData.DropdownValue.length && (
                                                                        DropDownData.DropdownValue.map((dropDownData2: any, dropDownIndex2: any) =>
                                                                            <MenuItem key={dropDownData2.dropDownIndex2} value={dropDownData2}>{dropDownData2 ? dropDownData2 : 'select'}</MenuItem>
                                                                        )
                                                                    )}
                                                                </CssTextField>
                                                                <span>&nbsp;{`${DropDownData.unitOfMeasurement}`}</span>
                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                                                <ClickAwayListener onClickAway={(e) => handHoverChange(index,false)}>
                                                                    <div>
                                                                        <HtmlTooltip
                                                                            PopperProps={{
                                                                                disablePortal: true,
                                                                            }}
                                                                            onClose={(e) => handHoverChange(index,false)}
                                                                            open={StaticHoverArray[index]}
                                                                            disableFocusListener
                                                                            disableHoverListener
                                                                            disableTouchListener
                                                                            title={t(detail.settingName+'Info')}
                                                                            arrow
                                                                            placement="right"
                                                                        >
                                                                            <InfoOutlinedIcon onClick={(e) => handHoverChange(index,true)} 
                                                                            onMouseOver={(e) => handHoverChange(index,true)} 
                                                                            />
                                                                        </HtmlTooltip>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </Grid>
                                                        </>
                                                    :''                     
                                                )}
                                                    
                                                {nameInDropdownDetails === false ?
                                                    <CssTextField
                                                        label=""
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        name={detail.settingName}
                                                        value={detail.settingValue}
                                                        className="settingDropdown"
                                                        onChange={(e) => handleChanage(e, index)}
                                                    />
                                                    :''
                                                }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        )}

                        {(user.attributes['custom:role'] === 'poweruser' && selectedPowerUserDetail?.Poweruser?.PoweruserGlobalPermission['SettingsWrite'] === false) ?
                            <Grid direction="row" container spacing={3}>
                                <Grid item xs={12}>
                                    <Button variant="outlined" onClick={updateSetting} disabled>
                                        {t('Update')}
                                    </Button>
                                </Grid>
                            </Grid> 
                            : 
                            <Grid direction="row" container spacing={3}>
                                <Grid item xs={12}>
                                    <Button variant="outlined" onClick={updateSetting} >
                                        {t('Update')}
                                    </Button>
                                </Grid>
                            </Grid> 
                            }
                    </>
                    : ''
                : ''}
        </div>
    );
}
