import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import "./userList.scss";
import TableView from "../table/table";
import { useTranslation } from "react-multi-lang";
import {
	getUser,
	createPowerUser,
	updateUserRole,
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import {
	SetAllUsers,
	SetSensorList,
	SetSelectedUserId,
} from "../../redux/action";
import _ from "lodash";

const header = [
	{ id: "name", label: "Name" },
	{ id: "username", label: "Username" },
	{ id: "address", label: "Address" },
	{ id: "place", label: "Place" },
	{ id: "Role", label: "Role" },

];

export default function UserList(props: any) {
	const history = useHistory();
	const user = useSelector((state: any) => state.LoginData);
	const dispatch = useDispatch();
	
	const [usersList, setUsersList] = useState([]);
	const [apiCall, setApiCall] = useState(false);
	const [search, setSearch] = useState("");

	const getAllUsers = async (value: any) => {
		let request = _.clone(getUser);
		request.url = request.url + "/?Search=" + '"' + value + '"';
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetAllUsers([]));
			
			setUsersList([]);
			dispatch(SetSensorList([]));
			dispatch(SetSelectedUserId(null));
			if (result.data.length) {
				dispatch(SetAllUsers(result.data));
				let array: any = [];
				result.data.map((detail: any, index: any) => {
					const data = {
						id: detail.UserID,
						name: detail.Users.Name,
						username: detail.Users.Username,
						address: detail.Users.Address.Street,
						place: detail.Users.Address.Place,
						isPowerUser : detail.Users.isPowerUser,
						Devices : detail.Users.Devices
					};
					array.push(data);
				});
				
				setUsersList(array);
			}
			setApiCall(true);
			setSearch(value);
		}
	};

	useEffect(() => {
		getAllUsers("");
	}, []);

	const handleSearch = (value: any) => {
		getAllUsers(value);
	};

	const clickOnRole = async (userID: any,role : any) => {
		console.log("Role.",role);
		let request;
		if(role === 'poweruser'){
			console.log("Poweruser..");
			request = _.clone(createPowerUser);
			request.url = request.url
			request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
			};
			const data : any = {
				"UserID" : userID
			}
			request.data = data
		}else{
			console.log("user......");
			request = _.clone(updateUserRole);
			request.url = request.url
			request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
			};
			const data : any = {
				"UserID" : userID
			}
			request.data = data			
		}
	
    const result: any = await getDataFromApi(request);

    if (result.data.message === "The incoming token has expired") {
      history.push("/");
    } else {
	  getAllUsers("");
    }
}

	return (
		<div className="userListMainDiv">
			<TableView
				tableHeader={header}
				tableBody={usersList}
				apiCall={apiCall}
				search={search}
				handleSearch={handleSearch}
				clickOnRole={clickOnRole}
			/>
            
		</div>
	);
}
