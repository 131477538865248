import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import "./table.scss";
import { withStyles, Theme, createStyles, makeStyles ,useTheme} from '@material-ui/core/styles';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination, TableSortLabel,useMediaQuery,
    TextField, CircularProgress, Dialog,DialogActions,DialogTitle,Button
} from '@material-ui/core/';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-multi-lang'
import _ from "lodash";
import {useLocation} from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';

interface Data { }

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}


interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    header: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort, header } = props;
    console.log("header......",header);
    const headCells: HeadCell[] = header;
    console.log("headCells........",headCells);
    
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const t = useTranslation()

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

export default function TableView(props: any) {

    let { tableHeader, tableBody, apiCall, deleteUseCase } = props;
    
    const classes = useStyles();
    const t = useTranslation()
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [usersList, setUsersList] = React.useState([]);
    const [useCaseId, setUseCaseId] = React.useState(null);
    const [cIndex, setIndex] = React.useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        console.log('This is placeholder code which is in no use.');
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#E7E7E7',
                },
                '&:nth-of-type(even)': {
                    backgroundColor: '#CBCBCB',
                },
            },
        }),
    )(TableRow);

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: '#c7d100',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#c7d100',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    //   borderColor: '#c7d100',
                },
                '&:hover fieldset': {
                    borderColor: '#c7d100',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#c7d100',
                },
            },
        },
    })(TextField);


    const handleDelete = (id: any,index : any) => {
        setUseCaseId(id)
        setIndex(index)
        setOpenDialog(true);
    }

    useEffect(() => {
        setUsersList(tableBody);
    }, [props.apiCall]);
    
    useEffect(() => {
        setUsersList(tableBody);
    }, [props.search]);

    useEffect(()=>{
        console.log("refresh");
    },[usersList])

const removeUseCase = () => {
    deleteUseCase(useCaseId,cIndex);
    setOpenDialog(false)
}

const handleClose = () => {
    setOpenDialog(false);
  };

    return (
        <div className={classes.root}>
            {/* <div className="searchField">
                <CssTextField id="outlined-basic" autoFocus={search?true:false} label={t("Search")} value={search} variant="outlined" onChange={(e) => handleUserSearch(e)} />
            </div> */}
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableBody.length}
                            header={tableHeader}
                        />
                        <TableBody>
                            {stableSort(tableBody, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index: any) => {
                                    const val = Object.keys(row)
                                    
                                    return (
                                        <StyledTableRow key={index}>
                                            <TableCell className="pointer" >{t(val[0])}</TableCell>
                                            <TableCell><DeleteIcon onClick={(e) => {handleDelete(val, index)
                                            }} /></TableCell>
                                        
                                            
                                        </StyledTableRow>     
                                    );
                                })}
                            {tableBody.length === 0 && apiCall &&(
                                <TableRow>
                                    <TableCell align="center" colSpan={4}>
                                        No Record Found
                                    </TableCell>
                                </TableRow>
                            )}
                            {!props.apiCall && (
                                <TableRow>
                                    <TableCell align="center" colSpan={4}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            
                        </TableBody>
                    </Table>
                </TableContainer>

            
        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className="dialog"
        >
          <DialogTitle id="responsive-dialog-title">{"Are you sure you want to delete this usecase?"}</DialogTitle>
          <DialogActions>
            <span className="deleteBtn">
              <Button variant="outlined" onClick={removeUseCase} color="primary">
                Delete
              </Button>
            </span>
            <span className="cancelBtn">
              <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                Cancel
          </Button>
            </span>
          </DialogActions>
        </Dialog>
        
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={tableBody.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
