import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Paper, CircularProgress } from "@material-ui/core";
import "./devices.scss";
import { getUser, UsersFromPowerUser } from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "react-notifications-component/dist/theme.css";
import { useTranslation } from "react-multi-lang";
import ChartDetail from "../dashboard/chartDetail";
import { Autocomplete } from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

const Devices: React.FC = (props: any) => {
	const t = useTranslation();
	const history = useHistory();
	const user = useSelector((state: any) => state.LoginData);

	const [userId, setUserId] = React.useState<string | null>(null);
	const [userList, setUserList] = useState([]);
	const [apiCall, setApiCall] = useState(false);

	useEffect(() => {
		getUserList();
	}, []);

	const getUserList = async () => {
		let request;
		if(user.attributes['custom:role'] === 'poweruser'){
			request = _.clone(UsersFromPowerUser);
			request.url = request.url + `/?UserID=${user.attributes.sub}`;
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
		}else{
		request = _.clone(getUser);
		request.url = request.url + '/?Search=""';
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
	}
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			if (result.data.length) {
				setUserList(result.data);
			}
			setApiCall(true);
		}
	};

	const handleChange = (e: any) => {
		if (e) {		
			setUserId(e);
		} else {
			setUserId(null);
		}
	};

	return (
		<>
			<div className="deviceMainDiv">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						{/* <CssTextField
                            select
                            className="sensorDropdown"
                            id="outlined-select-currency"
                            label={t("User")}
                            variant="outlined"
                            value={userId}
                            onChange={handleChange}
                        >
                            {
                                userList.length && (
                                    userList.map((data: any, index: any) =>
                                        <MenuItem key={data.UserID} value={data.UserID}>{t(data.Users.Name)}</MenuItem>
                                    )
                                )})
                        </CssTextField> */}
						{/* <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={userList.map((option:any) => option.Users.Name)}
                            renderInput={(params) => (
                                <TextField {...params} label="freeSolo" margin="normal" variant="outlined" />
                              )}
                        /> */}
						{apiCall ? (
							<Autocomplete
								id="combo-box-demo"
								onChange={(
									event: any,
									newValue: string | null
								) => {
									handleChange(newValue);
								}}
								// onChange={handleChange}
								options={userList}
								getOptionLabel={(option: any) =>
									option.Users.Name
								}
								renderInput={(params) => (
									<CssTextField
										{...params}
										label={t("User")}
										variant="outlined"
									/>
								)}
							/>
						) : (
							<Paper className="deviceListPaper">
								<CircularProgress />
							</Paper>
						)}
					</Grid>
				</Grid>
			</div>
			{userId ? (
				<div>
					<ChartDetail selectedUserId={userId} />
				</div>
			) : (
				""
			)}
		</>
	);
};
export default Devices;
