import React, {  useEffect } from "react";
import { useHistory } from "react-router-dom";
// import "./sensorList.scss";
import TableView from './useCaseTable'
import { useTranslation } from "react-multi-lang";
import {
	AllPowerUsers,
	updateUseCaseUser
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { SetSensorList, SetUseCaseList } from "../../redux/action";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { Paper, Grid,  } from "@material-ui/core";
import _ from "lodash";


const header = [
	{ id: "usecasename", label: "Usecase Name" },
	{ id: "action", label: "Action" },
];

export default function UseCaseList(props: any) {
	const history = useHistory();
	const selectedUSerId = useSelector((state: any) => state.userId);
	const selectedUserDetail = useSelector((state: any) => state.sensorList);
	const dispatch = useDispatch();
	const [allUseCaseList, setAllUseCaseList] = React.useState([]);
	const [apiCall, setApiCall] = React.useState(false);
	const user = useSelector((state: any) => state.LoginData);
   

	const getSensorListByUserId = async () => {
		let request;
			request = _.clone(AllPowerUsers);
			request.url = request.url + `/?UserID=${selectedUSerId}`;
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
		const result: any = await getDataFromApi(request);
		
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetSensorList([]));
			if (result.data.UserID) {
				dispatch(SetSensorList(result.data));
				setAllUseCaseList(result.data.Poweruser.PoweruserUsecases);
			}
			setApiCall(true);
		}
	};

	useEffect(() => {
		getSensorListByUserId();
	}, []);

	const addSensors = () => {
		history.push("/addUseCase");
	};

	const deleteUseCase = async (id : any, index : any) => {
		var oneAndThree = _.filter(allUseCaseList, function(x,index1) {
			let remove = Object.keys(x)
			return remove[0][index1] !== id[0][index]; 
		});
	
    let request = _.clone(updateUseCaseUser);
        request.url = request.url
        request.header = {
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
        };
    
        const data : any = {
            "UserID" : selectedUserDetail.UserID,
            "useCase" : oneAndThree
        }

        request.data = data;
        const result: any = await getDataFromApi(request);
        if (result.data.message === "The incoming token has expired") {
            history.push("/");
        } else{
            dispatch(SetUseCaseList(result.data.Attributes.Poweruser.PoweruserUsecases))
            getSensorListByUserId()
        }		
	}
	
	return (
		<div className="sensorListMainDiv">
			<Paper className="paperM">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<span>
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Name
								: ""}
							{", "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Street
								: ""}{" "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Place
								: ""}{" "}
							{selectedUserDetail.Poweruser
								? selectedUserDetail.Poweruser.Address.Postcode
								: ""}
						</span>
					</Grid>
				</Grid>
			</Paper>
			
			<TableView
				tableHeader={header}
				tableBody={allUseCaseList}
				apiCall={apiCall}
				deleteUseCase={deleteUseCase}
			/>

			<div className="addSensors">
				<AddCircleOutlinedIcon onClick={addSensors} />
			</div>
		</div>
	);
}
