import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./userList.scss";
import TableView from "../table/table";
import { useTranslation } from "react-multi-lang";
import {
	getUser,
	getDevice,
	getDeviceSetting,
	updateSensorSetting,
	UsersFromPowerUser,
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import {
	SetAllUsers,
	SetSensorList,
	SetSelectedUserId,
} from "../../redux/action";
import _ from "lodash";

const header = [
	{ id: "name", label: "Name" },
	{ id: "username", label: "Username" },
	{ id: "address", label: "Address" },
	{ id: "place", label: "Place" },
];

export default function UserList(props: any) {
	const t = useTranslation();
	const history = useHistory();
	const user = useSelector((state: any) => state.LoginData);
	const dispatch = useDispatch();
	const [allUserList, setallUserList] = React.useState([]);
	const [usersList, setUsersList] = React.useState([]);
	const [apiCall, setApiCall] = React.useState(false);
	const userList = useSelector((state: any) => state.allUsers);
	const [search, setSearch] = React.useState("");

	const getAllUsers = async (value: any) => {
		let request;
		if(user.attributes['custom:role'] === 'poweruser'){
			request = _.clone(UsersFromPowerUser);
			request.url = request.url + `/?Search=${value}&UserID=${user.attributes.sub}`;
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
		}else{
			request = _.clone(getUser);
			request.url = request.url + "/?Search=" + '"' + value + '"';
			request.header = {
				"Content-Type": "application/json",
				Authorization: user.signInUserSession.idToken.jwtToken,
			};
		}
		
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			dispatch(SetAllUsers([]));
			setallUserList([]);
			setUsersList([]);
			dispatch(SetSensorList([]));
			dispatch(SetSelectedUserId(null));
			if (result.data.length) {
				dispatch(SetAllUsers(result.data));
				let array: any = [];
				result.data.map((detail: any, index: any) => {
					const data = {
						id: detail.UserID,
						name: detail.Users.Name,
						username: detail.Users.Username,
						address: detail.Users.Address.Street,
						place: detail.Users.Address.Place,
						Poweruseraccess : detail.Users.Poweruseraccess
					};
					array.push(data);
				});
				setallUserList(array);
				setUsersList(array);
			}
			setApiCall(true);
			setSearch(value);
		}
	};

	useEffect(() => {
		getAllUsers("");
	}, []);

	const handleSearch = (value: any) => {
		getAllUsers(value);
	};

	return (
		<div className="userListMainDiv">
			<TableView
				tableHeader={header}
				tableBody={usersList}
				apiCall={apiCall}
				search={search}
				handleSearch={handleSearch}
			/>
		</div>
	);
}
