import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./setting.scss";
import {
	Typography,
	Grid,
	TextField,
	Paper,
	Button,
	MenuItem,
	FormControl,
	Dialog,
	DialogActions,
	DialogTitle,
	useMediaQuery,
	DialogContent,
	CircularProgress,
} from "@material-ui/core";
import TableView from '../table/table'
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { SetLanguage, LoginDetail, SetAllPowerUsers } from "../../redux/action";
import FormErrors from "../validation/FormErrors";
import Validate from "../validation/FormValidation";
import { Auth } from "aws-amplify";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { AllPowerUsers, DeletePowerUser, poweruserFromUsers, updatePowerUser, updateUser } from "../../services/constant";
import _ from "lodash";
import { getDataFromApi } from "../../services/api";
import { withStyles, useTheme } from "@material-ui/core/styles";

interface Types {
	auth: any;
}

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				//   borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				// borderColor: '#c7d100',
			},
		},
	},
})(TextField);

const language = [
	{ value: "en", name: "English" },
	{ value: "de", name: "German" },
	{ value: "fr", name: "French" },
	{ value: "it", name: "Italian" },
];

const Setting: React.FC = () => {
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [saveDisabled, setSaveDisabled] = useState(true);
	const user = useSelector((state: any) => state.LoginData);
	const userDetail = useSelector((state: any) => state.userDetail);
	const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog1, setOpenDialog1] = React.useState(false);
	const [code, setCode] = useState("");
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [updateContact, setUpdateContact] = useState(false);
	const setLanguage = useSelector((state: any) => state.languageData);
	const [allPowerUsers, setAllPowerUsersData] = React.useState([]);
	const [apiCall, setApiCall] = React.useState(false);
	const [sTypeId, SetSTypeId] = React.useState("");
	const [powerUserList, setpowerUserList] = React.useState([]);
	const [Poweruser, SetPowerUser] = React.useState([]);
	const [tempApiCall, setTempApiCall] = React.useState(false);


	
	const [state, setState] = useState({
		email: user && user.attributes["email"] ? user.attributes["email"] : "",
		surname:
			user && user.attributes["custom:custom:surname"]
				? user.attributes["custom:custom:surname"]
				: "",
		firstname:
			user && user.attributes["custom:custom:firstname"]
				? user.attributes["custom:custom:firstname"]
				: "",
		oldContact:
			user && user.attributes["custom:custom:contactno1"]
				? user.attributes["custom:custom:contactno1"]
				: "",
		contactno1:
			user && user.attributes["custom:custom:contactno1"]
				? user.attributes["custom:custom:contactno1"]
				: "",
		contactno2:
			user && user.attributes["custom:custom:contactno2"]
				? user.attributes["custom:custom:contactno2"]
				: "",
		contactno3:
			user && user.attributes["custom:custom:contactno3"]
				? user.attributes["custom:custom:contactno3"]
				: "",
		street:
			user && user.attributes["custom:custom:street"]
				? user.attributes["custom:custom:street"]
				: "",
		postcode:
			user && user.attributes["custom:custom:postcode"]
				? user.attributes["custom:custom:postcode"]
				: "",
		place:
			user && user.attributes["custom:custom:place"]
				? user.attributes["custom:custom:place"]
				: "",
		language:
			user && user.attributes["custom:custom:language"]
				? user.attributes["custom:custom:language"]
				: setLanguage,
		errors: {
			cognito: null,
			blankfield: false,
		},
	});

	const onInputChange = async (event: {
		target: { name: any; value: any };
	}) => {
		const { name, value } = event.target;
		await setState({ ...state, [name]: value });
		// if(event.target.name === 'language'){
		//     dispatch(SetLanguage(event.target.value))
		// }
		if (name === "contactno1") {
			setUpdateContact(true);
		}
	};

	useEffect(() => {
		ButtonValidate();
	}, [state]);

	useEffect(() => {
		// ButtonValidate();
		if (user.attributes["custom:role"] === "user") {
			PoweruserOfSelectedUser()
			getAllPowerusers("")
		}
	}, []);
	const ButtonValidate = () => {
		const { email, surname, firstname, contactno1 } = state;
		let saveDisabled = true;
		if (surname !== "" && firstname !== "" && contactno1 !== "") {
			saveDisabled = false;
		}
		setSaveDisabled(saveDisabled);
	};

	const clearErrorState = () => {
		setState({
			...state,
			errors: {
				cognito: null,
				blankfield: false,
			},
		});
	};

	const handleUpdate = async (event: { preventDefault: () => void }) => {
		event.preventDefault();
		// Form validation
		clearErrorState();
		const error = Validate(event, state);
		if (error) {
			setState({ ...state, ...error });
		}

		// AWS Cognito integration here
		const {
			email,
			surname,
			firstname,
			oldContact,
			contactno1,
			contactno2,
			contactno3,
			street,
			postcode,
			place,
			language,
		} = state;
		try {
			let user = await Auth.currentAuthenticatedUser();
			const signUpResponse = await Auth.updateUserAttributes(user, {
				email: email,
				"custom:custom:surname": surname,
				"custom:custom:firstname": firstname,
				phone_number: contactno1,
				"custom:custom:contactno1": contactno1,
				"custom:custom:contactno2": contactno2,
				"custom:custom:contactno3": contactno3,
				"custom:custom:language": language,
			});

			if (updateContact) {
				try {
					setOpenDialog(true);
				} catch (error) {
					setOpenDialog(false);
					let err = null;
					!error.message ? (err = { message: error }) : (err = error);
					setState({
						...state,
						errors: { cognito: err, blankfield: false },
					});
				}
			} else {
				setUpdatedUserDetail();
			}
		} catch (error) {
			let err = null;
			!error.message ? (err = { message: error }) : (err = error);
			setState({ ...state, errors: { cognito: err, blankfield: false } });
		}
	};

	const setUpdatedUserDetail = async () => {
		let user = await Auth.currentAuthenticatedUser();

		const {
			email,
			surname,
			firstname,
			contactno1,
			contactno2,
			contactno3,
			street,
			postcode,
			place,
			language,
		} = state;
		dispatch(LoginDetail(user));
		dispatch(SetLanguage(user.attributes["custom:custom:language"]));
		store.addNotification({
			message: "Update Successfully !..",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 1000,
				onScreen: true,
			},
		});
		const contacts = [];
		if (contactno1) {
			contacts.push(contactno1);
		}
		if (contactno2) {
			contacts.push(contactno2);
		}
		if (contactno3) {
			contacts.push(contactno3);
		}

		try {
			if (user.attributes && user.attributes["custom:role"] === "user") {
				let request = _.clone(updateUser);
				request.url = request.url;
				request.header = {
					"Content-Type": "application/json",
					Authorization: user.signInUserSession.idToken.jwtToken,
				};
				const data = {
					UserID: user.attributes.sub,
					Users: {
						Address: {
							Place: user.attributes["custom:custom:place"],
							Postcode: user.attributes["custom:custom:postcode"],
							Street: user.attributes["custom:custom:street"],
						},
						Devices: userDetail.Users.Devices,
						Email: email,
						IsActive: true,
						Language: language,
						Name: surname + " " + firstname,
						Phone: contacts,
						Poweruseraccess: {},
						Type: "",
						Username: user.username,
					},
				};
				request.data = data;
				const result: any = await getDataFromApi(request);
				if (result.data.message === "The incoming token has expired") {
					history.push("/");
				}
			}
		} catch (error) {}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleClose1 = () => {
		setOpenDialog1(false);
	};

	const onInputCodeChange = (event: {
		target: { name: any; value: any };
	}) => {
		const { name, value } = event.target;
		setCode(value);
	};

	const handleVerifiedCode = () => {
		try {
			Auth.verifyCurrentUserAttributeSubmit("phone_number", code)
				.then((data) => {
					setOpenDialog(false);
					setUpdatedUserDetail();
					setState({
						...state,
						errors: { cognito: null, blankfield: false },
					});
					setUpdateContact(false);
				})
				.catch((error) => {
					let err = null;
					!error.message ? (err = { message: error }) : (err = error);
					setState({
						...state,
						errors: { cognito: err, blankfield: false },
					});
				});
		} catch (error) {
			let err = null;
			!error.message ? (err = { message: error }) : (err = error);
			setState({ ...state, errors: { cognito: err, blankfield: false } });
		}
	};

	const header1 = [
		{ id: "Name", label: "Name Poweruser" },
		{ id: "address", label: "Address" },
		{ id: "action", label: "Action" },
	]

	const PoweruserOfSelectedUser = async () => {
		let request = _.clone(poweruserFromUsers);
		request.url = request.url + `/?UserID=${user.attributes.sub}`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			
			dispatch(SetAllPowerUsers([]));
				dispatch(SetAllPowerUsers(result.data));
				setAllPowerUsersData(result.data);
			setApiCall(true);
		}
	}

	const deletePowerUser = async (powerUserID : any) => {
		
		let request = _.clone(DeletePowerUser);
		request.url = request.url + `/?UserID=${user.attributes.sub}`;
        request.header = {
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
        };
		const data : any = {
			"UserID" : user.attributes.sub,
			"powerUserID" : powerUserID
		}
       request.data = data
        const result: any = await getDataFromApi(request);
		
        if (result.data.message === "The incoming token has expired") {
            history.push("/");
        } else{
			// getSensorListByUserId()
		   PoweruserOfSelectedUser()
        }
	}
	
	const addPoweruser = () => {
		setOpenDialog1(true)
		// history.push('/addPoweruser')
	}
	
	const getAllPowerusers = async (value: any) => {
		let request = _.clone(AllPowerUsers);
        request.url = request.url + `/?Search=${value}`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
        
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
            
			dispatch(SetAllPowerUsers([]));
			setpowerUserList([]);
			SetSTypeId("");
			SetPowerUser([]);
			if (result.data) {
					dispatch(SetAllPowerUsers(result.data));
					setpowerUserList(result.data);
			    }
		}
	};

	const PowerUserChange = async (e: any) => {
		SetSTypeId(e.target.value);
		SetPowerUser([]);
		const promises = powerUserList.map((detail: any, index: any) => {
			if (detail.UseCaseId === e.target.value) {
				SetPowerUser(detail.Poweruser);
			}
		});
	};

	const addNewPoweruser = async () => {	
		setApiCall(true);
		let request = _.clone(updatePowerUser);
		request.url = request.url
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		console.log("request header",request.header);

		const data : any = {
			"UserID" : user.attributes.sub,
			"powerUserID" : sTypeId
		}

		request.data = data;
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else{
			if(user.attributes['custom:role'] === 'user'){
				history.push('/setting')
			}else{
				history.push('/sensors')
			}       
			// setApiCall(false);
			setOpenDialog1(false)
		}
		SetSTypeId("")
		PoweruserOfSelectedUser()
	};

	return (
		<div className="setting">
			<>
				<Grid direction="row" container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h4">{t("Settings")}</Typography>
					</Grid>
				</Grid>
				<Paper className="settingPapper">
					<Grid direction="row" container spacing={3}>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Surname")}*:</Typography>
							<CssTextField
								label=""
								required
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.surname}
								name="surname"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Telephone Number")} 1*:</Typography>
							<CssTextField
								label=""
								required
								id="outlined-basic"
								variant="outlined"
								placeholder="+41763337766"
								value={state.contactno1}
								name="contactno1"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Firstname")}*:</Typography>
							<CssTextField
								label=""
								required
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.firstname}
								name="firstname"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Telephone Number")} 2:</Typography>
							<CssTextField
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.contactno2}
								name="contactno2"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Street")}*:</Typography>
							<CssTextField
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.street}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Telephone Number")} 3:</Typography>
							<CssTextField
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.contactno3}
								name="contactno3"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Postcode")}*:</Typography>
							<CssTextField
								required
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.postcode}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Email")}:</Typography>
							<CssTextField
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.email}
								name="email"
								onChange={onInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Place")}*:</Typography>
							<CssTextField
								label=""
								id="outlined-basic"
								variant="outlined"
								defaultValue={state.place}
								disabled
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<Typography>{t("Language")}*:</Typography>
							<FormControl
								variant="outlined"
								className="sLangDropdown"
							>
								<CssTextField
									select
									variant="outlined"
									label=""
									value={state.language}
									name="language"
									onChange={onInputChange}
								>
									{language.map((lan) => (
										<MenuItem value={lan.value}>
											{lan.name}
										</MenuItem>
									))}
								</CssTextField>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div className="errorMsg">
								<FormErrors formerrors={state.errors} />
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={6}></Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							className="settingSubmit"
						>
							<Button
								variant="outlined"
								disabled={saveDisabled}
								onClick={handleUpdate}
							>
								{t("Save")}
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</>

			<>
				<Dialog
					fullScreen={fullScreen}
					open={openDialog}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
					className="dialog"
				>
					<DialogTitle id="responsive-dialog-title">
						{t("Please, Enter verification code")}
					</DialogTitle>
					<DialogContent>
						<CssTextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							defaultValue={code}
							onChange={onInputCodeChange}
							type="password"
							id="code"
							label={t("Code")}
							name="code"
						/>
					</DialogContent>
					<DialogContent>
						<div className="errorMsg">
							<FormErrors formerrors={state.errors} />
						</div>
					</DialogContent>
					<DialogActions>
						<span className="cancelBtn">
							<Button
								variant="outlined"
								onClick={handleVerifiedCode}
								color="primary"
							>
								{t("Verify")}
							</Button>
						</span>
						{/* <span className="cancelBtn">
                            <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </span> */}
					</DialogActions>
				</Dialog>

				<Dialog
					fullScreen={fullScreen}
					open={openDialog1}
					onClose={handleClose1}
					aria-labelledby="responsive-dialog-title"
					className="dialog"
					>
					<DialogTitle id="responsive-dialog-title">{t("Add Poweruser")}</DialogTitle>
					<DialogContent>
					{/* <Grid container spacing={3}>
						<Grid item xs={12} sm={3} md={3} lg={3}> */}
							{
							// scanIndex === null ? (
								apiCall ? (
									<CssTextField
										select
										className="sensorDropdown"
										id="outlined-select-currency"
										label="Type"
										variant="outlined"
										value={sTypeId}
										onChange={(e) => PowerUserChange(e)}
									>
										{powerUserList.length &&
											powerUserList.map(
												(data: any, index: any) => (
													<MenuItem
														key={data.UserID}
														value={data.UserID}
													>
														{t(data.Poweruser.Name)}
													</MenuItem>
												)
											)}
										)
									</CssTextField>
								) : (
									<Paper className="addSensorPaper">
										<CircularProgress />
									</Paper>
								)
							// ) : (
							// 	<Button variant="outlined" onClick={backToAdd}>
							// 		{t("Back")}
							// 	</Button>
							// )
							}
							
					</DialogContent>
					<DialogActions>
						<span className="deleteBtn">
						<Button variant="outlined"  onClick={addNewPoweruser}  color="primary">
							Add
						</Button>
						</span>
						<span className="cancelBtn">
						<Button autoFocus variant="outlined" onClick={handleClose1} color="primary">
							Cancel
					</Button>
						</span>
					</DialogActions>
				</Dialog>
			</>
<br />
<br />
	{
	(user.attributes['custom:role'] === 'user') ?
		<div>
			<TableView
				tableHeader1={header1}
				tableBody={allPowerUsers}
				apiCall={apiCall}
				deletePowerUser={deletePowerUser}
			/>
			<br />
			<div className="addSensors">
				<Button id="addPower"  onClick={addPoweruser}>add Poweruser</Button>
			</div>
		</div>

		: ""
	}		
		</div>
	);
};

export default Setting;
