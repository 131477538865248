import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Grid,
	MenuItem,
	CircularProgress,
	TextField,
	Button,
	Paper,
	Dialog,
	DialogActions,
	DialogTitle,
	useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-multi-lang";
import {
    updatePowerUser,
    AllPowerUsers,
	getUser
} from "../../services/constant";
import { getDataFromApi } from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import {  SetAllPowerUsers } from "../../redux/action";
import _ from "lodash";
import { withStyles, useTheme } from "@material-ui/core/styles";
import QrReader from "react-qr-reader";
// import "./addSensor.scss";

const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: "#c7d100",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#c7d100",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				// borderColor: '#c7d100',
			},
			"&:hover fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#c7d100",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#c7d100",
			},
		},
	},
})(TextField);

const AddPoweruser: React.FC = (props: any) => {
	const t = useTranslation();
	const history = useHistory();
	const userId = useSelector((state: any) => state.userId);
	let allUsers = useSelector((state: any) => state.allUsers);
	const dispatch = useDispatch();
	const [powerUserList, setpowerUserList] = React.useState([]);
	const [sTypeId, SetSTypeId] = React.useState("");
	const [Poweruser, SetPowerUser] = React.useState([]);
	const [apiCall, setApiCall] = React.useState(false);
	const [result, setResult] = React.useState("No result");
	const [scanIndex, setScanIndex] = React.useState(null);
	const [tempApiCall, setTempApiCall] = React.useState(false);
	const user = useSelector((state: any) => state.LoginData);
	const sUserDetail = useSelector((state: any) => state.sensorList);
	const [selectedUserDetail, setSelectedUserDetail] =
		React.useState(sUserDetail);
    const selectedUSerId = useSelector((state: any) => state.userId);
	const [existDeviceId, setExistDeviceId] = React.useState(null);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [openDialog, setOpenDialog] = React.useState(false);
	// const [oneUser,setOneUser] = React.useState([])
	const [name,setName] = React.useState("")
	const [address,setaddress] = React.useState("")
	const [Place,setPlace] = React.useState("")
	const [Postcode,setPostcode] = React.useState("")

	
	let UserID : any;
	if(user.attributes['custom:role'] === 'user'){
		UserID = user.attributes.sub
	}else{
		UserID = selectedUSerId
		
	}
	
	
	const getAllPowerusers = async (value: any) => {
		let request = _.clone(AllPowerUsers);
        request.url = request.url + `/?Search=${value}`;
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
        
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
            
			dispatch(SetAllPowerUsers([]));
			setpowerUserList([]);
			SetSTypeId("");
			SetPowerUser([]);
			// SetUserTemplate([]);
			// SetDeviceTemplate([]);
			if (result.data) {
					dispatch(SetAllPowerUsers(result.data));
					setpowerUserList(result.data);
			    }
			setTempApiCall(true);
		}
	};

	useEffect(() => {
		getAllPowerusers("");
		getUserDetails()
	}, []);

	const getUserDetails = async () =>{
		let request = _.clone(getUser);
		request.url = request.url + "/?UserID=" + '"' + user.attributes.sub + '"';
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else {
			console.log("result.",result);
			// setOneUser([])
			setName("")
			setaddress("")
			setPlace("")
			setPostcode("")

			if(result.data){
				setName(result.data.Users.Name)
				setaddress(result.data.Users.Address.Street)
				setPlace(result.data.Users.Address.Place)
				setPostcode(result.data.Users.Address.Postcode)
			}			
		}	
	}

	const handleChange = async (e: any) => {
		SetSTypeId(e.target.value);
		SetPowerUser([]);
		const promises = powerUserList.map((detail: any, index: any) => {
            console.log("detaillsss...",detail);
            
			if (detail.UseCaseId === e.target.value) {
				SetPowerUser(detail.Poweruser);
				// SetUserTemplate(detail.Poweruser.userTemplate);
				// SetDeviceTemplate(detail.template.DeviceSettingsTemplate);
			}
		});
	};

	const handleError = (err: any) => {
		console.error(err);
	};
	const handleScan = (data: any) => {
		let value = data;
		setResult(value);
	};

	const backToAdd = () => {
		setScanIndex(null);
	};

	const addDeviceDetail = async () => {
       
       	
		setApiCall(true);
		
		let request = _.clone(updatePowerUser);
		request.url = request.url
		request.header = {
			"Content-Type": "application/json",
			Authorization: user.signInUserSession.idToken.jwtToken,
		};
        console.log("request header",request.header);

        const data : any = {
			"UserID" : UserID,
			"powerUserID" : sTypeId
		}

		request.data = data;
		const result: any = await getDataFromApi(request);
		if (result.data.message === "The incoming token has expired") {
			history.push("/");
		} else{
            if(user.attributes['custom:role'] === 'user'){
				history.push('/setting')
			}else{
				history.push('/sensors')
			}       
			setApiCall(false);
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const overwriteSensorDetail = () => {
		let templateDetail: any = Poweruser;
		let index = _.findIndex(
			templateDetail.DeviceSettingsTemplate,
			function (o: any) {
				return o.DeviceID === existDeviceId;
			}
		);
		templateDetail.DeviceSettingsTemplate[index].update = true;
		setExistDeviceId(null);
		setOpenDialog(false);
		addDeviceDetail();
	};
	return (
		<div className="addSensorDiv">
			<Paper className="paperM">
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						{
							(user.attributes['custom:role'] === 'user') ?
							// "User"
							<span>
								{name}{", "}{address}{" "}{Place}{" "}{Postcode}
							</span>
							
						:
						<span>
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Name
								: ""}
							{", "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Street
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Place
								: ""}{" "}
							{selectedUserDetail.Users
								? selectedUserDetail.Users.Address.Postcode
								: ""}
						</span>
					}
					</Grid>
				</Grid>
			</Paper>

			<Grid container spacing={3}>
				<Grid item xs={12} sm={3} md={3} lg={3}>
					{scanIndex === null ? (
						tempApiCall ? (
							<CssTextField
								select
								className="sensorDropdown"
								id="outlined-select-currency"
								label="Type"
								variant="outlined"
								value={sTypeId}
								onChange={(e) => handleChange(e)}
							>
								{powerUserList.length &&
									powerUserList.map(
										(data: any, index: any) => (
											<MenuItem
												key={data.UserID}
												value={data.UserID}
											>
												{t(data.Poweruser.Name)}
											</MenuItem>
										)
									)}
								)
							</CssTextField>
						) : (
							<Paper className="addSensorPaper">
								<CircularProgress />
							</Paper>
						)
					) : (
						<Button variant="outlined" onClick={backToAdd}>
							{t("Back")}
						</Button>
					)}
				</Grid>
			</Grid>

			{scanIndex !== null ? (
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3} md={3} lg={3}>
						<QrReader
							delay={300}
							facingMode={"environment"}
							showViewFinder={true}
							onError={handleError}
							onScan={handleScan}
						/>
					</Grid>
				</Grid>
			) : powerUserList.length ? (
				<>
					<br />
					<Grid item xs={12}>
						<Button
							variant="outlined"
							disabled={apiCall ? true : false}
							onClick={addDeviceDetail}
						>
							{apiCall ? <CircularProgress /> : t("Update")}
						</Button>
					</Grid>
					
					<Dialog
						fullScreen={fullScreen}
						open={openDialog}
						onClose={handleClose}
						aria-labelledby="responsive-dialog-title"
						className="dialog"
					>
						<DialogTitle id="responsive-dialog-title">
							{"Wan't to overwrite existing sensor settings? (DeviceID = " +
								existDeviceId +
								")"}
						</DialogTitle>
						<DialogActions>
							<span className="deleteBtn">
								<Button
									variant="outlined"
									onClick={overwriteSensorDetail}
									color="primary"
								>
									Overwrite
								</Button>
							</span>
							<span className="cancelBtn">
								<Button
									autoFocus
									variant="outlined"
									onClick={handleClose}
									color="primary"
								>
									Cancel
								</Button>
							</span>
						</DialogActions>
					</Dialog>
				</>
			) : (
				""
			)}
		</div>
	);
};
export default AddPoweruser;
